import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const BookDemoPage = () => (
  <Layout navbarTheme="dark" className="bg-cream">
    <Seo title="Book a Demo" canonical="https://tio.ist/digital-consultancy/" />
 
    <div className="relative py-20">

      <div className="container text-center mx-auto  py-10 lg:px-10 max-w-xs lg:max-w-xl">
      <h1 className="text-5xl  font-bold text-blue">
      You Deserve a Better Workflow
      </h1>
      <p className="text-xl p-10 font-light">
      We will make a discovery call to discuss what can we do for you and your business.
      </p>
      <div className="hidden absolute z-1 -top-10 right-0 lg:block">
          <StaticImage  src="../images/demo-arrow.png"  width={300}  alt="tio" />
        </div>
      <form action="/success" name="demo"  method="post" netlify-honeypot="bot-field" data-netlify="true" >
            <input type="hidden" name="form-name" value="demo" />
            <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="text" name="name" placeholder="Name Surname *" />
            <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="text" name="name" placeholder="Company Name *" />
            <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="email" name="email" placeholder="E-mail *" />
            <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" type="text" name="phone" placeholder="Phone" />
            <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" type="text" name="companySize" placeholder="Company Size" />
            <textarea className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required rows="5" name="message" placeholder="Tell us what you are interested in. Data analysis, e-commerce, product management...">       
            </textarea>
            <button className="bg-blue px-5 rounded mt-5 py-2 text-white" type="submit" >Send</button>
        </form>
      </div>
    </div>
    


  </Layout>
)

export default BookDemoPage
